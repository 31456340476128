import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Insurance from "../components/insurance"

const IndexPage = () => (
  <Layout>
    <Seo />  
    <Insurance />
  </Layout>
);

export default IndexPage;
